import React, { Fragment } from "react";
import styled from "styled-components";

import Button from "../components/button";

// import ResumePDF from "../assets/brooke-nicholas-resume.pdf"

const Schools = styled.div`
  margin-bottom: 2rem;
`;
const School = styled.div`
  margin-bottom: 1rem;
  :first-of-type {
    margin-top: 1rem;
  }
`;
School.Name = styled.p``;
School.Degree = styled.p``;
School.Information = styled.p`
  opacity: 0.7;
`;

const Experiences = styled.div`
  margin-bottom: 2rem;
`;
const Experience = styled.div`
  margin-bottom: 1.5rem;
  :first-of-type {
    margin-top: 1rem;
  }
`;

const Header = styled.p`
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

Experience.Employer = styled.p``;
Experience.Position = styled.p``;
Experience.City = styled.p``;
Experience.Dates = styled.p``;
Experience.Information = styled.p`
  opacity: 0.7;
  margin-bottom: 0.5rem;
`;
Experience.Description = styled.p`
  max-width: 600px;
`;

const BiographyContainer = ({ biography }) => {
  const ResumePDF = biography.childMarkdownRemark.frontmatter.resume;
  return (
    <Fragment>
      <Schools>
        <Header>Education</Header>
        <School>
          <School.Name>
            <a
              href="https://ccs.bard.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bard College, Center for Curatorial Studies
            </a>
          </School.Name>
          <School.Degree>Master of Arts, 2020</School.Degree>
          <School.Information>Annandale-on-Hudson, NY</School.Information>
        </School>
        <School>
          <School.Name>
            <a
              href="https://www.bu.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Boston University
            </a>
          </School.Name>
          <School.Degree>
            Major in Art History, Minor in Visual Arts, 2013
          </School.Degree>
          <School.Information>Boston, MA</School.Information>
        </School>
      </Schools>
      <Experiences>
        <Header>Experience</Header>
        <Experience>
          <Experience.Employer>
            <a href="https://www.abronsartscenter.org/">Abrons Arts Center</a>
          </Experience.Employer>
          <Experience.Position>Curator in Residence</Experience.Position>
          <Experience.Information>
            New York, NY | March 2019 – February 2020
          </Experience.Information>
          <Experience.Description>
            Collaborated with Director of Programming, Ali Rosa-Salas and
            Fashion Historian, Kimberly Jenkins to develop the photographic
            exhibition Rainbow Shoe Repair: An Unexpected Theater of Flyness, as
            well as public programming. The exhibition focused on local
            portraiture and identity in the late 1980s and early 2000s in
            downtown New York. It received favorable press from the New York
            Times, The Cut, and Cultured Magazine.
          </Experience.Description>
        </Experience>
        <Experience>
          <Experience.Employer>
            <a href="http://rossin.co/">Rachel Rossin</a>
          </Experience.Employer>
          <Experience.Position>Studio Manager</Experience.Position>
          <Experience.Information>
            New York, NY | June 2017 – August 2018
          </Experience.Information>
          <Experience.Description>
            I supported contemporary painter and digital artist Rachel Rossin
            with administrative and procedural tasks related to her studio
            practice. This included facilitating creation of new works,
            overseeing contracts, creating and maintaining a budget, hiring and
            managing freelancers, matters related to scheduling and
            communication, planning and executing strategic partnerships as well
            as managing budgets.
          </Experience.Description>
        </Experience>
        <Experience>
          <Experience.Employer>
            <a href="https://www.artsy.net/">Artsy</a>
          </Experience.Employer>
          <Experience.Position>
            Associate Curator for Artsy Projects
          </Experience.Position>
          <Experience.Information>
            New York, NY | April 2016 - June 2017
          </Experience.Information>
          <Experience.Description>
            Ideated and planned, artist projects, curated content, and brand
            partnerships by creating experiential activations and supporting the
            creation of new artworks. Tasks included coordinating artists,
            vendors, and contractors in the production of site specific
            installations, events, and editioned works. Artist collaborations
            included The Gorilla Girls, Jacolby Satterwhite and Misha Kahn.
          </Experience.Description>
        </Experience>
        <Experience>
          <Experience.Employer>
            <a href="https://hammer.ucla.edu/">Hammer Museum</a>
          </Experience.Employer>
          <Experience.Position>
            Executive Assistant to the Director
          </Experience.Position>
          <Experience.Information>
            Los Angeles, CA | April 2014 - February 2015
          </Experience.Information>
          <Experience.Description>
            Lead administrative order to Director Ann Philbin. Duties included
            calendar management, scheduling, maintaining the directors office
            department budget and credit card accounts, organizing domestic and
            international travel, office maintenance, filing and archiving, as
            well as external and internal communication.
          </Experience.Description>
        </Experience>
        <Experience>
          <Experience.Employer>
            <a href="https://artistsspace.org/">
              Artists Space / Artists Space Books and Talks
            </a>
          </Experience.Employer>
          <Experience.Position>
            Executive Assistant to the Director
          </Experience.Position>
          <Experience.Information>
            New York, NY | May - November, 2012
          </Experience.Information>
          <Experience.Description>
            Lead intern alongside Assistant Curator Jenny Borland to prepare,
            planned and organized for the exhibit, 1,000 Years of Wasted Youth:
            Bernadette Corporation. Oversaw Books and Talks event space by
            overseeing purchases, maintaining an archive, and supporting gallery
            events. For Artists Space, I provided archival services, including
            scanning, cataloguing, and digitizing over 50 years of historical
            material related to exhibition publications and ancillary materials.
          </Experience.Description>
        </Experience>
      </Experiences>
      <a
        href={ResumePDF}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button>Download as PDF</Button>
      </a>
    </Fragment>
  );
};

export default BiographyContainer;
