import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Seo from "../../components/seo";

import Biography from "../../containers/biography";

const ExperiencePage = ({ data }) => {
  const biography = data.allFile.edges[0].node;
  return (
    <Fragment>
      <Seo title="Biography" />
      <Biography biography={biography} />
    </Fragment>
  );
};

export const fileQuery = graphql`
  query BiographyQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "files" }, name: { eq: "biography" } }
    ) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              resume
            }
          }
        }
      }
    }
  }
`;

export default ExperiencePage;
